import React, { useState, useEffect } from "react";

import AddEMS from "./AddEMS";
import AddSLC from "./AddSLC";
import AddWattrouter from "./AddWattrouter";
//import ErrorToast from "../Toasts/ErrorToast";
import { getFetcher, postFetcher } from "../fetcher";

const AddDevice = () => {
  const devices = ["EMS", "SLC", "Wattrouter"];

  //const [error, setError] = useState(false);
  //const [errorMsg, setErrorMsg] = useState("");
  const [device, setDevice] = useState(devices[0]);

  const [name, setName] = useState("");
  const [sn, setSN] = useState("");

  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("");

  const [emsDevices, setEmsDevices] = useState([]);
  const [emsDevice, setEmsDevice] = useState([]);

  useEffect(() => {
    loadUsers();
    loadDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUsers = () => {
    getFetcher("/users").then((data) => {
      setUsers(data);
      setUser(data[0]._id);
    });
  };

  const loadDevices = () => {
    getFetcher("/devices").then((data) => {
      //filter only EMS devices
      setEmsDevices(data.all_devices.filter((device) => device.type === "EMS"));
      setEmsDevice(
        data.all_devices.filter((device) => device.type === "EMS")[0]._id
      );
    });
  };

  const addDevice = () => {
    let dtOut = {
      owner: user,
      name: name,
      type: device,
      sn: sn,
    };

    if (device === "SLC") {
      dtOut.ems = emsDevice;
    } else if (device === "Wattrouter") {
      dtOut.ems = emsDevice;
    }

    postFetcher("/device/create", dtOut).then((data) => {
      //setError(false);
      console.log("add device", data);
      //setName("");
      //setSN("");
      document.getElementById("dismissButton").click();
      //navigate("/device/" + data._id);
      //navigate("/devices");
      if (data) {
        window.location.reload();
      }
      //window.location.reload();
    });

    /*
    fetch(api_url + "/device/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        name: name,
        [device === "EMS" ? "ems" : "slc"]: id,
        type: device,
      }),
    })
      .then((res) => {
        if (res.status === 201) {
          return res.json();
        } else {
          console.log(res);
          setError(true);
          setErrorMsg("Error adding device");
        }
      })
      .then((data) => {
        //console.log(data);
        setError(false);
        setName("");
        setId("");

        //reload page
        //window.location.reload();
        document.getElementById("dismissButton").click();
        navigate("/device/" + data._id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
      */
    //dismis modal after 1s
    //setTimeout(() => {
    //  document.getElementById("dismissButton").click();
    //}, 1000);
  };
  return (
    <>
      {/*error && <ErrorToast text={errorMsg} handler={setError} />*/}
      <button
        type="button"
        className="btn btn-success mt-3 mb-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal">
        Add Device
      </button>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Device
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <select
                className="form-select"
                onChange={(e) => setDevice(e.target.value)}>
                {devices.map((device) => (
                  <option value={device}>{device}</option>
                ))}
              </select>
              <div className="mt-3">
                <label htmlFor="owner" className="form-label">
                  Owner
                </label>
                <select
                  className="form-select"
                  id="owner"
                  aria-describedby="ownerHelp"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}>
                  {users.map((user) => (
                    <option value={user._id}>
                      {user.name +
                        " " +
                        user.lastname +
                        " (" +
                        user.email +
                        ")"}
                    </option>
                  ))}
                </select>
                <div id="ownerHelp" className="form-text">
                  Owner of the device
                </div>
              </div>
              <div className="mt-3">
                {device === "EMS" && (
                  <AddEMS name={name} setName={setName} sn={sn} setSN={setSN} />
                )}
                {device === "SLC" && (
                  <AddSLC
                    name={name}
                    setName={setName}
                    sn={sn}
                    setSN={setSN}
                    emsDevices={emsDevices}
                    setEMSDevice={setEmsDevice}
                  />
                )}
                {device === "Wattrouter" && (
                  <AddWattrouter
                    name={name}
                    setName={setName}
                    sn={sn}
                    setSN={setSN}
                    emsDevices={emsDevices}
                    setEMSDevice={setEmsDevice}
                  />
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                id="dismissButton"
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal">
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={addDevice}>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDevice;
